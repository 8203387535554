class SocketAdapter {
  listeners = []

  constructor(url) {
    this.connect(url)
  }

  connect(url) {
    this.socket = new WebSocket(url)
    this.socket.addEventListener('open', this.onOpen)
    this.socket.addEventListener('message', this.onMessage)
    this.socket.addEventListener('close', (event) => {
      console.log("WEBSOCKET CLOSED: ", event)
      this.connect(url)
    })
  }

  onOpen() {}

  onMessage = (message) => {
    const {type, data} = JSON.parse(message.data)
    this.listeners.map((listener) => {
      if (listener.type === type) listener.handler(data)
    })
  }

  listen(type, handler) {
    this.listeners.push({ type, handler })
  }

  send = (data) => {
    this.socket.send(JSON.stringify(data))
  }
}

export default SocketAdapter
