import React, { Component } from "react"
import classNames from "classnames"

// icons
import closeIcon from "../../assets/icons/icon-close.svg"

// context
import { SocketContext } from "../../context"

class InputUsername extends Component {
  static contextType = SocketContext
  input = React.createRef()
  state = { username: "" }

  submitUserName = (event) => {
    event.preventDefault()
    if (!this.state.username) return
    this.context.send({
      type: "message",
      data: this.state.username
    })
  }

  handleInput = (event) => {
    this.setState({username: event.target.value})
  }

  componentDidUpdate() {
    if (this.props.hasFocus) this.input.current.focus()
  }

  render() {
    const { username } = this.state
    const { hasFocus, toggleView } = this.props

    return (
      <form
        onSubmit={this.submitUserName}
        className={classNames("container fixed-bottom form-group modal", {"show": hasFocus})}
      >
        <h3 className="h2 mt-0 mb-05">Your Name</h3>
        <button
          className="close"
          onClick={toggleView}
        >
          <img src={closeIcon} alt="close" />
        </button>
        <input
          ref={this.input}
          onChange={this.handleInput}
          className="form-control mb-1"
          placeholder="Enter your Name..."
          value={username}
        />
        <button className="btn btn-default">Confirm Name</button>
      </form>
    )
  }
}

export default InputUsername
