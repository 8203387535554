import React, { Component } from "react"

import Spinner from "../components/Spinner"

class View extends Component {
  timesCalled = 0
  cleverPush = null
  state = {
    disabled: true,
    checked: false,
    loading: true
  }

  componentDidMount() {
    this.initCleverPush()
  }

  initCleverPush = () => {
    if (typeof Notification === "undefined") return
    this.cleverPush = window.CleverPush
    this.timesCalled++
    if (this.timesCalled > 10) return
    if (!this.cleverPush) return setTimeout(this.initCleverPush, 500)

    if (Notification.permission === "denied") return
    this.checkState()

    this.cleverPush.on("SUBSCRIBED", this.checkState)
    this.cleverPush.on("UNSUBSCRIBED", this.checkState)
  }

  checkState = () => {
    if (Notification.permission === "denied") {
      return this.setState({disabled: true, loading: false})
    }
    this.cleverPush.push(['isSubscribed', (isSubscribed) => {
      this.setState({checked: isSubscribed, disabled: false, loading: false})
    }])
  }

  onToggleSwitch = () => {
    this.cleverPush.push(['isSubscribed', (isSubscribed) => {
      this.setState({loading: true})
      this.cleverPush.push([isSubscribed ? 'unsubscribe' : 'subscribe', () => {
        this.checkState()
      }])
    }])
  }

  render() {
    const {disabled, checked, loading} = this.state

    return (
      <div className="page">
        <h1 className="h1 m-0 position-relative">Settings</h1>
        <h2 className="h2 m-0">Notifications</h2>
        <ul className="list-unstyled text-left mt-2 container">
          <li className="bg-lighter card d-flex justify-between align-center">
            Mitteilungen erlauben
            {loading && (<Spinner width="26px" height="26px" />)}
            {!loading && (
              <input
                id="switcher"
                type="checkbox"
                onChange={this.onToggleSwitch}
                disabled={disabled}
                checked={checked}
              />
            )}
            {!loading && (
              <label htmlFor="switcher">Notifications</label>
            )}
          </li>
        </ul>
        <div className="d-flex mb-2 fixed-bottom">
          <a className="mr-1" target="_blank" href="impressum.html">
            Datenschutz
          </a>
          <a target="_blank" href="datenschutz.html">
            Impressum
          </a>
        </div>
      </div>
    )
  }
}

export default View
