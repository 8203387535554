import { Howl, Howler } from "howler"

Howler.autoUnlock = false

class Player {
  constructor(url) {
    this.volume = 100
    this.isLoading = false
    this.isPlaying = false
    this.url = url
    this.listeners = []
    this.isInitialized = false
  }

  init = () => {
    this.stream = new Howl({
      src: [this.url],
      html5: true
    })
    this.stream.on('loaderror', () => {
      console.log("loaderror")
      this.stream.once('unlock', () => {
        this.stream.play();
      });
    })
    this.stream.on('load', error => console.log("load: ", error))
    this.stream.on('play', error => console.log("play: ", error))
    this.stream.on('end', () => this.stream.play())
    this.stream.on('stop', error => console.log("stop: ", error))
    this.stream.on('pause', error => console.log("pause: ", error))
    this.stream.on('playerror', error => console.log("playerror: ", error))
    this.stream.on('loaderror', error => console.log("loaderror: ", error))
    this.stream.on('unlock', error => console.log("unlock: ", error))
    this.stream.on('seek', error => console.log("seek: ", error))
    this.stream.on('rate', error => console.log("rate: ", error))
    this.isInitialized = true
    this.listeners.map(({event, handler}) => {
      this.stream.on(event, handler)
    })
    this.listeners = []
  }

  play = () => {
    this.stream.play()
  }

  stop = () => {
    this.stream.stop()
  }

  on = (event, handler) => {
    if (!this.stream) return this.listeners.push({event, handler})
    this.stream.on(event, handler)
  }
}

export default Player
