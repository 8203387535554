import React from "react"
import ReactDOM from "react-dom"
import "./swiper.css"
import "./index.css"
import App from "./components/App"
import * as serviceWorker from "./serviceWorker"

// classes
import SocketAdapter from "./classes/socketAdapter"
import Player from "./classes/player"

const updateHeight = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

updateHeight()
window.addEventListener('resize', updateHeight);
window.addEventListener('load', updateHeight);

// context
const url = "https://stream.playpause.de/stream"
const player = new Player(url)
const socket = new SocketAdapter("wss://playpause.de/ws/")

const startApp = (props) => {
  updateHeight()
  ReactDOM.render(
    <React.StrictMode>
      <App {...props} />
    </React.StrictMode>,
    document.getElementById('root')
  )
}

socket.listen("init", (data) => {
  startApp({socket, player, data})
})
serviceWorker.register()
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
