import React, { Component } from "react"
import classNames from "classnames"
import { getTime } from "./helper"

class Message extends Component {
  render() {
    const {time, message, username, isOwner} = this.props

    return (
      <li className={classNames("bg-lighter message", {"message-left": !isOwner, "message-right": isOwner})}>
        <h3 className="h3 d-flex justify-between align-center mt-0">
          {username} <span className="text-muted">{getTime(time)}</span>
        </h3>
        <p className="m-0">{message}</p>
      </li>
    )
  }
}

class Messages extends Component {
  render() {
    const { messages, username } = this.props
    const items = messages.slice().reverse()

    return (
      <ul className="list-unstyled scroll text-left mt-7 container">
        {items.map((message, index) => <Message
          {...message}
          key={`message-${index}`}
          isOwner={username === message.username}
        />)}
      </ul>
    )
  }
}

export default Messages
