import React, { Component } from "react"

import Spinner from "../components/Spinner"

class View extends Component {
  svgPlayPath = "M11,8 L18,11.74 18,20.28 11,24 11,8 M18,11.74 L26,16 26,16 18,20.28 18,11.74"
  svgPausePath = "M9,8 L14,8 14,24 9,24 9,8 M19,8 L24,8 24,24 19,24 19,8"
  animation = React.createRef()
  state = {
    isPlaying: false,
    isLoading: false,
    svgPath: this.svgPlayPath,
    animationProps: {}
  }

  componentDidMount() {
    this.props.player.on("play", this.startPlayAnimation)
    this.props.player.on("stop", this.startStopAnimation)
  }

  togglePlayer = () => {
    this.setState({isLoading: true})
    if (this.state.isPlaying) {
      if (this.props.player.isInitialized) {
        this.props.player.stop()
      }
    } else {
      if (!this.props.player.isInitialized) {
        this.props.player.init()
      }
      this.props.player.play()
    }
  }

  startPlayAnimation = () => {
    this.setState({
      isPlaying: true,
      isLoading: false,
      animationProps: this.toPauseAnimation,
      svgPath: this.svgPausePath
    })
    this.animation.current.beginElement()
  }

  startStopAnimation = () => {
    this.setState({
      isPlaying: false,
      isLoading: false,
      animationProps: this.toPlayAnimation,
      svgPath: this.svgPlayPath
    })
    this.animation.current.beginElement()
  }

  get toPlayAnimation() {
    return {
      from: this.svgPausePath,
      to: this.svgPlayPath
    }
  }

  get toPauseAnimation() {
    return {
      from: this.svgPlayPath,
      to: this.svgPausePath
    }
  }

  render() {
    const { dj = "", song = "" } = this.props
    const { animationProps, svgPath, isLoading } = this.state
    const songInfo = song.split(" - ")

    return (
      <div className="page">
        <div
          className="h-50 d-flex align-center justify-end position-relative"
        >
          <button id="btn-play-pause" onClick={this.togglePlayer} disabled={isLoading}>
            {isLoading && (<Spinner id="loading-svg" width="50px" height="50px" />)}
            {!isLoading && (
              <svg
                id='play-svg' xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 32 32'
              >
                <path d={svgPath}>
                  <animate
                    ref={this.animation}
                    attributeName='d' fill='freeze' dur='0.1s'
                    calcMode='spline' keySplines='0.19 1 0.22 1'
                    {...animationProps}
                  />
                </path>
              </svg>
            )}
          </button>
        </div>
        <div
          className="h-50 justify-center position-relative align-center d-flex container"
        >
          <h5 className="h5 d-flex align-center">
            <span className="live d-none" />
            Now live
          </h5>
          <h1 className="h1 m-0">{dj}</h1>
          <h2 className="h2 m-0 position-relative move track">
            {songInfo.reduce((result, item) => (
              <>{result} <span className="mr-05 ml-05">–</span> {item}</>
            ))}
          </h2>
        </div>
      </div>
    )
  }
}

export default View