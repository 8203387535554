import React, { Component } from 'react'
import classNames from "classnames"

// components
import Messages from "../components/Messages"
import MessageInput from "../components/MessageInput"
import UsernameInput from "../components/UsernameInput"

// context
import { SocketContext } from "../context"


class View extends Component {
  static contextType = SocketContext

  constructor(props) {
    super(props);
    this.state = {
      messages: props.messages || [],
      showUsernameInput: false,
      messageHasFocus: false,
      username: "",
      text: ""
    }
  }

  componentDidMount() {
    this.context.listen("message", (message) => {
      const messages = this.state.messages
      messages.push(message)
      this.setState({messages, messageHasFocus: true})
    })
    this.context.listen("username", (username) => {
      this.username = username
      this.setState({username, showUsernameInput: false, messageHasFocus: true})
    })
  }

  toggleUsernameInput = () => {
    if (this.username) return
    const showUsernameInput = !this.state.showUsernameInput
    this.setState({showUsernameInput})
  }

  render() {
    const { messages, showUsernameInput, messageHasFocus } = this.state

    return (
      <div className="page justify-start">
        <Messages messages={messages} username={this.username} />
        <MessageInput
          hasFocus={messageHasFocus}
          username={this.username}
          onClickForm={this.toggleUsernameInput}
        />
        <UsernameInput
          hasFocus={showUsernameInput}
          toggleView={this.toggleUsernameInput}
        />
        <div className={classNames("modal-overlay", {"show": showUsernameInput})} />
      </div>
    )
  }
}

export default View