import React, { Component } from "react"
import SwipeableViews from "react-swipeable-views"

// components
import Navigation from "../../components/Navigation"

// views
import SettingsView from "../../views/settings"
import MainView from "../../views/main"
import ChatView from "../../views/chat"

// context
import { SocketContext } from "../../context"

class App extends Component {
  state = {
    navIndex: 1,
    navPosition: 1,
    tmpIndex: null,
    hasNewMessages: true
  }

  componentDidMount() {
    this.props.socket.listen("message", () => {
      if (this.state.navIndex !== 2) {
        this.setState({hasNewMessages: true})
      }
    })
  }

  onChangeIndex = (navPosition) => {
    this.setState({navPosition})
  }

  onSwitching = (navPosition) => {
    this.setState({navPosition})
  }

  onTransitionEnd = () => {
    this.setState({
      tmpIndex: null,
      navPosition: this.state.navPosition,
      navIndex: this.state.navPosition
    })
    this.checkNewMessages(this.state.navPosition)
  }

  changeIndex = (tmpIndex) => () => {
    this.setState({tmpIndex, navPosition: tmpIndex})
    this.checkNewMessages(tmpIndex)
  }

  checkNewMessages = (index) => {
    if (index === 2) this.setState({hasNewMessages: false})
  }

  render() {
    const { navIndex, navPosition, tmpIndex, hasNewMessages } = this.state
    const { data, player, socket } = this.props
    const index = Number.isInteger(tmpIndex) ? tmpIndex : navIndex

    return (
      <div className="swiper-container bg-dark">
        <SocketContext.Provider value={socket}>
          <Navigation
            hasNewMessages={hasNewMessages}
            changeIndex={this.changeIndex}
            index={navIndex}
            position={navPosition}
          />
          <SwipeableViews
            enableMouseEvents
            index={index}
            onChangeIndex={this.onChangeIndex}
            onSwitching={this.onSwitching}
            onTransitionEnd={this.onTransitionEnd}
          >
            <SettingsView />
            <MainView data={data} player={player} />
            <ChatView messages={data.history}  />
          </SwipeableViews>
        </SocketContext.Provider>
      </div>
    )
  }
}

export default App
