import React, { Component } from "react"

// context
import { SocketContext } from "../../context"

class InputMessage extends Component {
  static contextType = SocketContext
  input = React.createRef()
  state = {
    text: ""
  }

  submitMessage = (event) => {
    event.preventDefault()
    if (!this.props.username || !this.state.text) return
    this.context.send({
      type: "message",
      data: this.state.text
    })
    this.setState({text: ""})
    this.input.current.focus()
  }

  handleInput = (event) => {
    this.setState({text: event.target.value})
  }

  componentDidUpdate() {
    if (this.props.hasFocus) this.input.current.focus()
  }

  render() {
    const { text } = this.state

    return (
      <form
        className="container fixed-bottom form-group bg-dark mb-1"
        onSubmit={this.submitMessage}
        onClick={this.props.onClickForm}
      >
        <input
          ref={this.input}
          onChange={this.handleInput}
          value={text}
          className="form-control absolute-bottom"
          placeholder="Nachricht verfassen..."
        />
        <svg
          className="input-icon" width="24px" height="24px"
          onClick={this.submitMessage}
          viewBox="0 0 24 24" version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Shape</title>
          <g
            id="App" stroke="none" strokeWidth="1" fill="none"
            fillRule="evenodd"
          >
            <path
              d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"
              id="Shape" fill="#FFFFFF" fillRule="nonzero"
            />
          </g>
        </svg>
      </form>
    )
  }
}

export default InputMessage
