import React, { Component } from "react"

const Link = ({icon = "link", title, description, link}) => (
  <li className="bg-lighter d-flex align-center rounded position-relative mb-05 hover">
    <div className="bg-primary p-2 rounded">
      <i className={"fab fa-" + icon + " fa-2x"} />
    </div>
    <div className="pl-2">
      <h3 className="h3 mt-0 mb-05"><a
        className="link-before" href={link} target="_blank"
      >{title}</a></h3>
      <p className="m-0 small">{description}</p>
    </div>
  </li>
)
class View extends Component {

  render() {
    const { links = [] } = this.props

    return (
      <div className="page">
        <h1 className="h1 m-0 position-relative">Offline</h1>
        <h2 className="h2 m-0">Artists</h2>
        <ul className="list-unstyled scroll text-left mt-2 container">
          {links.map((link, index) => <Link key={`link-${index}`} {...link} />)}
        </ul>
        <div className="mb-2 fixed-bottom">
          <a className="btn btn-outline d-chrome" href="#">App Download</a>
        </div>
      </div>
    )
  }
}

export default View