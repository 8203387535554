import React, { Component } from "react"

// views
import OfflineView from "../views/offline"
import OnlineView from "../views/online"

// context
import { SocketContext } from "../context"

class View extends Component {
  static contextType = SocketContext

  constructor(props) {
    super(props)
    this.state = {
      isOnline: props.data.isOnline,
      links: props.data.links || [],
      dj: props.data.dj || "",
      song: props.data.song || ""
    }
  }

  componentDidMount() {
    this.context.listen("isOnline", (isOnline) => {
      this.setState({isOnline})
      if (!isOnline) this.props.player.stop()
    })
    this.context.listen("links", (links) => {
      this.setState({links})
    })
    this.context.listen("dj", (dj) => {
      this.setState({dj})
    })
    this.context.listen("song", (song) => {
      this.setState({song})
    })
  }

  render() {
    const { isOnline, links, dj, song } = this.state

    return isOnline
      ? <OnlineView dj={dj} song={song} player={this.props.player} />
      : <OfflineView links={links} />
  }
}

export default View